@import "~tachyons/css/tachyons.min.css";
@import "~antd/dist/antd.less";
@import "~stream-chat-react/dist/css/index.css";
@import "~react-image-gallery/styles/css/image-gallery.css";
@import "./antd.customize.less";

@bottom-shadow: rgba(0, 0, 0, 0.1) 0 0 15px 0;

body {
  margin: 0;
  font-family: "Avenir Next", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: @layout-body-background;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header-logo {
  float: left;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 5px;

  .str-chat__input-footer {
    display: none;
  }
}

.str-chat {
  height: 500px;
}

.messaging.str-chat .str-chat__header-livestream {
  z-index: auto;

  .str-chat__header-livestream-left--members {
    display: none;
  }
}

.react-pdf__Page__canvas {
  max-width: 100%;
  height: auto !important;
}

.vxt-widget-container,
.voxeet-loading-message-container {
  z-index: 9999 !important;
}

.ant-layout-header {
  height: auto;
  box-shadow: @bottom-shadow;
}

.ant-modal-header {
  border-bottom: none;
}

.mw-80 {
  max-width: 80%;
}

.mw-90 {
  max-width: 90%;
}

.mw-rem-80 {
  max-width: 80rem;
}

@pc-green: #1bdc77;
@pc-green-hover: tint(@pc-green, 30%);

.btn-success {
  background-color: @pc-green;
  border-color: @pc-green;
  color: @white;
  font-weight: 500;

  &:hover {
    background-color: @pc-green-hover;
    border-color: @pc-green-hover;
    color: @white;
  }
}

.bottom-shadow {
  box-shadow: @bottom-shadow;
}

@pc-shadow: 0 10px 25px rgba(19, 30, 65, 0.096524);

.pc-shadow {
  box-shadow: @pc-shadow;
}

.image-gallery-slide {
  opacity: 0;
  transition: opacity 450ms ease-out !important;
}
.image-gallery-slide.center {
  opacity: 1;
}

.firebaseui-container {
  font-family: "Avenir Next", sans-serif !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  max-width: 400px !important;
  box-shadow: @pc-shadow !important;
}

#prezcall-auth-page-root.prezcall-auth {
  & .firebaseui-title {
    display: none;
  }

  // ToDo: extract common styles
  & .firebaseui-id-secondary-link.firebaseui-button.mdl-button--primary {
    color: #000;
    background: #fff;
    border-color: #000;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
    border-radius: 5px;
    outline: 0;
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
  }

  & .firebaseui-button {
    color: #fff;
    background: #000;
    border-color: #000;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
    border-radius: 5px;
    outline: 0;
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
  }
}

#root {
  .ant-list-empty-text {
    color: rgba(0, 0, 0, 0.5);
  }

  .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
}

.vxt-widget-container {
  z-index: 99999999999 !important;
}
